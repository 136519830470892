<template>
  <div>
    <CCard>
      <CCardHeader>
        <strong>Filtrar </strong>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="2">
            <CInput type="date" label="Desde" v-model="search.date_initial">
            </CInput>
          </CCol>
          <CCol sm="2">
            <CInput
              type="date"
              label="Hasta"
              :min="search.date_initial"
              v-model="search.date_end"
              :max="hoy"
            >
            </CInput>
          </CCol>
          <CCol sm="1">
            <label for="">&nbsp;</label>
            <br />
            <CButton color="info" class="w-100 text-center" @click="mtd_getData()">
              <i class="fas fa-search"></i>
            </CButton>
          </CCol>
          <CCol lg="3" md="3" xs="12" sm="12">
            <label for="">&nbsp;</label>
            <br />
            <CButton
              @click="mtd_download"
              :disabled="data.length == 0 ? true : false"
              color="success"
              class="w-100"
              ><i class="fas fa-file-excel"></i> Pendientes</CButton
            >
          </CCol>
          <CCol lg="3" md="3" xs="12" sm="12">
            <label for="">&nbsp;</label>
            <br />
            <CButton
              @click="mtd_download_atention"
              :disabled="data.length == 0 ? true : false"
              color="info"
              class="w-100"
              ><i class="fas fa-file-excel"></i> Atendidos</CButton
            >
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CRow>
      <CCol lg="12" md="12" xs="12" sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de ordenes de compra"
          icon="fas fa-edit"
          btn_name=""
          :size="'md'"
          :button_new="false"
          :actions="true"
          :withActions="'15%'"
          :buttonEdit="false"
          :buttonDelete="true"
          @action_delete="mtd_delete"
          :myButtons="myButtons"
          @mtd_programer="mtd_programer"
          @mtd_cancel="mtd_cancel"
          @mtd_atender="mtd_atender"
          @mtd_view_det="mtd_view_det"
          @mtd_edit_numoc="mtd_edit_numoc"
        />
      </CCol>

      <!-- modal delete -->
      <cModalDelete
        title="Borrar Compra"
        :boo_modal="modal_delete.boo"
        :item="modal_delete.item"
        @close_delete="close_delete"
        @mtd_commit="mtd_commit"
      ></cModalDelete>

      <!-- modal programar -->
      <cModalProgramer
        title="Programar Orden Compra"
        :boo_modal="modal_programer.boo"
        :item="modal_programer.item"
        @close_delete_programer="close_delete_programer"
        @mtd_commit_programer="mtd_commit_programer"
      ></cModalProgramer>

      <!-- modal cancelar -->
      <cModalCancelar
        title="Cancelar Orden Compra"
        :boo_modal="modal_cancel.boo"
        :item="modal_cancel.item"
        @close_delete_cancel="close_delete_cancel"
        @mtd_commit_cancel="mtd_commit_cancel"
      ></cModalCancelar>

      <!-- modal atender -->
      <cModalAtender
        title="Atender Orden Compra"
        :boo_modal="modal_atender.boo"
        :item="modal_atender.item"
        @close_delete_atender="close_delete_atender"
        @mtd_commit_atender="mtd_commit_atender"
      ></cModalAtender>

      <!-- modal view -->
      <cModalViewDet
        title="Detalle"
        :boo_modal="modal_view.boo"
        :item="modal_view.item"
        :obs="modal_view.obs"
        :enc="modal_view.enc"
        @close_view="close_view"
      ></cModalViewDet>

      <!-- modal view -->
      <cModalEditNumOc
        title="Editar OC"
        :boo_modal="modal_edit.boo"
        :data="modal_edit.data"
        @close_delete_edit="close_delete_edit"
        @mtd_commit_edit="mtd_commit_edit"
      ></cModalEditNumOc>
    </CRow>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { bus } from "../../main";

import exportFromJson from "export-from-json";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import cModalDelete from "../../components/shared/modals/cModalDelete.vue";
import CTableWrapper from "../../components/shared/datatable/Table.vue";
import cModalProgramer from "../../components/shared/process/cModalProgramer.vue";
import cModalCancelar from "../../components/shared/process/cModalCancelar.vue";
import cModalAtender from "../../components/shared/process/cModalAtender.vue";
import cModalViewDet from "../../components/shared/process/cModalViewDet.vue";
import cModalEditNumOc from "../../components/shared/process/cModalEditNumOc.vue";

const fields = [
  { key: "Id", _style: "width:3%" },
  { key: "client", label: "Cliente", _style: "width:20%;" },
  { key: "guia", label: "Guia", _style: "width:6%;" },
  { key: "number", label: "Num Oc", _style: "width:5%;" },
  { key: "coti", label: "Cotización", _style: "width:5%;" },
  { key: "costo", label: "Costo", _style: "width:5%;" },
  { key: "ganancia", label: "Ganancia", _style: "width:5%;" },
  { key: "venta", label: "Venta", _style: "width:5%;" },
  { key: "status", label: "Estado", _style: "width:10%;" },
];

export default {
  name: "v-order",
  components: {
    CTableWrapper,
    vSelect,
    cModalDelete,
    cModalProgramer,
    cModalCancelar,
    cModalAtender,
    cModalViewDet,
    cModalEditNumOc,
  },
  data() {
    return {
      prefix: "proccess/order",
      fields,
      hoy: null,
      /** filtro */
      search:{
        date_initial:null,
        date_end:null,
      },
      /** */
      data: [],
      excel: [],
      excel_atention:[],
      myButtons: [
        {
          class: "btn-sm ml-1 text-center",
          color: "info",
          tooltip: "Editar Num Orden",
          action: "mtd_edit_numoc",
          icon: "fas fa-edit",
        },
        {
          class: "btn-sm ml-1 text-center",
          color: "success",
          tooltip: "Ver detalle",
          action: "mtd_view_det",
          icon: "fas fa-eye",
        },
        {
          class: "btn-sm ml-1 text-center",
          color: "info",
          tooltip: "Programar",
          action: "mtd_programer",
          icon: "fas fa-calendar",
          show: {
            1: true,
            2: false,
            3: false,
            4: false,
          },
        },
        {
          class: "btn-sm ml-1 text-center",
          color: "success",
          tooltip: "Atender",
          action: "mtd_atender",
          icon: "fas fa-plus",
          show: {
            1: true,
            2: true,
            3: false,
            4: false,
          },
        },
        {
          class: "btn-sm ml-1 text-center",
          color: "warning",
          tooltip: "Cancelar",
          action: "mtd_cancel",
          icon: "fas fa-times-circle",
          show: {
            1: true,
            2: true,
            3: false,
            4: false,
          },
        },
      ],
      /** modal delete */
      modal_delete: {
        boo: false,
        item: [],
      },
      /** programar */
      modal_programer: {
        boo: false,
        item: [],
      },
      /** Cancelar */
      modal_cancel: {
        boo: false,
        item: [],
      },
      /** Atender */
      modal_atender: {
        boo: false,
        item: [],
      },
      /** modal view */
      modal_view: {
        boo: false,
        item: [],
        obs:"",
        enc:""
      },
      /** modal edit */
      modal_edit: {
        boo: false,
        item: [],
        data:{
          guia:"",
          num:""
        }
      },
    };
  },
  created() {
    let actual = new Date();
    let fecha;
    fecha =
      actual.getFullYear() +
      "-" +
      ("0" + (actual.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + actual.getDate()).slice(-2);
    this.hoy = fecha;
    this.search.date_initial=fecha;
    this.search.date_end=fecha;
    this.mtd_getData();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getData: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix,
        token: this.$store.getters.get__token,
        params:this.search
      })
        .then((response) => {
          // console.log(response);
          this.data = response.data;
          this.data.forEach((element) => {
            if (element.status == "Pendiente") {
              let det = element.detail;
              det.forEach((ele) => {
                this.excel.push({
                  Cliente: element.client,
                  Guia: element.guia,
                  OC: element.number,
                  Cotizción: element.coti,
                  'Costo C/IGV': element.costo,
                  'Ganancia C/IGV': element.ganancia,
                  'Venta C/IGV': element.venta,
                  Estado: element.status,
                  Producto: ele.description,
                  Unidad: ele.unit,
                  Proveedor: ele.proveedor,
                  Cantidad: ele.quantity,
                  Costo: ele.costo,
                  "Costo S/IGV": ele.cost_sin_igv,
                  Porcentaje: ele.porcentaje,
                  "Venta S/IGV": ele.price_venta,
                  "subtotal Venta": ele.subtotal_venta,
                  "subtotal Costo": ele.subtotal_costo,
                  'subtotal Ganancia': ele.subtotal_ganancia,
                });
              });
            }
            if (element.status == 'Atendido') {
              let det = element.detail;
              det.forEach((ele) => {
                this.excel_atention.push({
                  Cliente: element.client,
                  Guia: element.guia,
                  OC: element.number,
                  Cotizción: element.coti,
                  'Costo C/IGV': element.costo,
                  'Ganancia C/IGV': element.ganancia,
                  'Venta C/IGV': element.venta,
                  Estado: element.status,
                  Producto: ele.description,
                  Unidad: ele.unit,
                  Proveedor: ele.proveedor,
                  Cantidad: ele.quantity,
                  Costo: ele.costo,
                  "Costo S/IGV": ele.cost_sin_igv,
                  Porcentaje: ele.porcentaje,
                  "Venta S/IGV": ele.price_venta,
                  "subtotal Venta": ele.subtotal_venta,
                  "subtotal Costo": ele.subtotal_costo,
                  'subtotal Ganancia': ele.subtotal_ganancia,
                  'Mes atendido': element.month_atention
                });
              });
            }
          });
        })
        .catch((errors) => {});
    },
    /** delete */
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.Id,
        },
      })
        .then((response) => {
          if (response.state == 0) {
            bus.$emit("alert", {
              color: "success",
              message: "ELIMINADO CORRECTAMENTE!!",
            });
            this.mtd_getData();
          } else if (response.state == 2) {
            bus.$emit("alert", {
              color: "warning",
              message: response.message,
            });
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: response.message,
            });
          }
          this.close_delete();
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    /** programar */
    mtd_programer: function (item) {
      this.modal_programer.boo = true;
      this.modal_programer.item = item;
    },
    close_delete_programer: function () {
      this.modal_programer.boo = false;
      this.modal_programer.item = [];
    },
    mtd_commit_programer: function (date) {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/date",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_programer.item.Id,
          date: date,
        },
      })
        .then((response) => {
          if (response.state == 0) {
            bus.$emit("alert", {
              color: "success",
              message: "PROGRAMADO CORRECTAMENTE!!",
            });
            this.mtd_getData();
            this.close_delete_programer();
          } else if (response.state == 2) {
            bus.$emit("alert", {
              color: "warning",
              message: response.message,
            });
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: response.message,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    /** Cancelar */
    mtd_cancel: function (item) {
      this.modal_cancel.boo = true;
      this.modal_cancel.item = item;
    },
    close_delete_cancel: function () {
      this.modal_cancel.boo = false;
      this.modal_cancel.item = [];
    },
    mtd_commit_cancel: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/cancel",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_cancel.item.Id,
        },
      })
        .then((response) => {
          if (response.state == 0) {
            bus.$emit("alert", {
              color: "success",
              message: "CANCELADO CORRECTAMENTE!!",
            });
            this.mtd_getData();
            this.close_delete_cancel();
          } else if (response.state == 2) {
            bus.$emit("alert", {
              color: "warning",
              message: response.message,
            });
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: response.message,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    /** Atender */
    mtd_atender: function (item) {
      this.modal_atender.boo = true;
      this.modal_atender.item = item;
    },
    close_delete_atender: function () {
      this.modal_atender.boo = false;
      this.modal_atender.item = [];
    },
    mtd_commit_atender: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/atender",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_atender.item.Id,
        },
      })
        .then((response) => {
          if (response.state == 0) {
            bus.$emit("alert", {
              color: "success",
              message: "ATENDIDO CORRECTAMENTE!!",
            });
            this.mtd_getData();
            this.close_delete_atender();
          } else if (response.state == 2) {
            bus.$emit("alert", {
              color: "warning",
              message: response.message,
            });
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: response.message,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    /** view det */
    mtd_view_det: function (item) {
      console.log(item);
      this.modal_view.boo = true;
      this.modal_view.item = item.detail;
      this.modal_view.obs = item.quote.observation;
      this.modal_view.enc = item.quote.tecnico;
    },
    close_view: function () {
      this.modal_view = {
        boo: false,
        item: [],
        obs:"",
        enc:""
      };
    },
    /** edit num oc */
    mtd_edit_numoc: function (item) {
      this.modal_edit.boo = true;
      this.modal_edit.item = item.Id;
      this.modal_edit.data={
        guia:item.guia != null? item.guia:"",
        num:item.number != null? item.number:""
      };
      console.log(this.modal_edit.data);
    },
    close_delete_edit: function () {
      this.modal_edit = {
        boo: false,
        item: [],
        data:{
          guia:"",
          num:""
        }
      };
    },
    mtd_commit_edit: function (number,guia) {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/editnumoc",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_edit.item,
          number: number,
          guia:guia
        },
      })
        .then((response) => {
          if (response.state == 0) {
            bus.$emit("alert", {
              color: "success",
              message: "EDITADO CORRECTAMENTE!!",
            });
            this.mtd_getData();
            this.close_delete_edit();
          } else if (response.state == 2) {
            bus.$emit("alert", {
              color: "warning",
              message: response.message,
            });
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: response.message,
            });
          }
          this.close_delete();
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_download: function () {
      const data = this.excel;
      const fileName = "pendientes";
      const typeExport = exportFromJson.types.xls;
      exportFromJson({
        data: data,
        fileName: fileName,
        exportType: typeExport,
      });
    },
    mtd_download_atention: function(){
      const data = this.excel_atention;
      const fileName = "atendidos";
      const typeExport = exportFromJson.types.xls;
      exportFromJson({
        data: data,
        fileName: fileName,
        exportType: typeExport,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
</style>