<template>
    <CModal
      :show.sync="boo_modal"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Modal title 2"
      :size="size"
      color="info"
    >
        <div class="row">
            <div class="col-md-12 float-center" style="text-align: center;">
                <CInput
                    v-model="data.num"
                    label="Nuevo número de OC"
                    placeholder="Número de OC"
                ></CInput>
            </div>
            <div class="col-md-12 float-center" style="text-align: center;">
                <CInput
                    v-model="data.guia"
                    label="Nuevo número de guía"
                    placeholder="Número de guía"
                ></CInput>
            </div>
        </div>
      <template #header>
        <h6 class="modal-title">{{title}}</h6>
        <CButtonClose @click="mtd_close" class="text-white"/>
      </template>
      <template #footer>
        <CButton @click="mtd_close" color="danger">Cancelar</CButton>
        <CButton @click="mtd_commit" :disabled="cp_button" color="success">Aceptar</CButton>
      </template>
    </CModal>
</template>

<script>
    export default {
        name:"c-modal-delete",
        props:{
            title: String, 
            boo_modal:Boolean,
            size:String,
            data:{
                type:Object,
                default:{
                    num:"",
                    guia:""
                }
            }
        },
        data() {
            return {
                number:"",
                guia:""
            }
        },
        computed: {
            cp_button(){
                if (this.data.num.length>0 && this.data.guia.length>0) return false;
                return true;
            }
        },
        methods: {
            mtd_close(){
                this.$emit('close_delete_edit');
            },
            mtd_commit(){
                this.$emit('mtd_commit_edit',this.data.num,this.data.guia);
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>