<template>
  <CModal
    :show.sync="boo_modal"
    :no-close-on-backdrop="true"
    :centered="false"
    title="Modal title 2"
    color="info"
  >
    <CRow v-if="item != undefined">
      <CCol lg="12" md="12" sm="12" xs="12">
        <CInput
            type="date"
            label="Fecha"
            v-model="date"
        />
      </CCol>
    </CRow>
    <template #header>
      <h6 class="modal-title">{{ title }}</h6>
      <CButtonClose @click="mtd_close" class="text-white" />
    </template>
    <template #footer>
      <CButton @click="mtd_close" color="danger">Cancelar</CButton>
      <CButton @click="mtd_commit" color="info">Guargar</CButton>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "c-modal-view",
  data() {
    return {
      date:null,
    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
    item: {},
    size: String,
  },
  created(){
    let actual = new Date();
    let fecha;
    fecha =
      actual.getFullYear() +
      "-" +
      ("0" + (actual.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + actual.getDate()).slice(-2);
    this.date = fecha;
  },
  computed: {    
  },
  methods: {
    mtd_close() {
      this.$emit("close_delete_programer");
    },
    mtd_commit: function(){
      this.$emit("mtd_commit_programer",this.date)
    }
  },
};
</script>

<style lang="scss" scoped>
</style>